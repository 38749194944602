import React from "react"
import WhatLayout from "../../components/WhatLayout";
import bild1 from "../../images/what/selfies/geweih-1.png";
import bild2 from "../../images/what/selfies/geweih-2.png";
import bild3 from "../../images/what/selfies/geweih-3.png";
import bild4 from "../../images/what/selfies/geweih-4.png";
import bild5 from "../../images/what/selfies/geweih-5.png";
import bild6 from "../../images/what/selfies/selbstbildnis-2018.png";
import bild7 from "../../images/what/selfies/selbstbildnis-cam-2018.png";
import bild8 from "../../images/what/selfies/selbstbildnis-sickhead.png";

const Page = () => {

  return (
    <WhatLayout
      header={"Selfies"}
      images={[
        bild1,
        bild2,
        bild3,
        bild4,
        bild5,
        bild6,
        bild7,
        bild8
      ]}
    />
  );

};

export default Page;
